import { isEntry } from './isEntry';
import type { IEntry } from '~/lib/ContentfulService';

export function isEntryOfContentType<
  C extends IEntry['sys']['contentType']['sys']['id']
>(
  value: unknown,
  contentType: C
): value is Extract<IEntry, { sys: { contentType: { sys: { id: C } } } }> {
  return isEntry(value) && value.sys?.contentType?.sys?.id === contentType;
}
