import type { LinkFragment } from '~/lib/ContentfulGraphqlService';

export function isLinkFragment(input: unknown): input is LinkFragment {
  return (
    typeof input === 'object' &&
    input != null &&
    '__typename' in input &&
    input.__typename === 'Link'
  );
}
