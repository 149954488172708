import type { NavigationItemFragment } from '~/lib/ContentfulGraphqlService';

export function isNavigationItemFragment(
  input: unknown
): input is NavigationItemFragment {
  return (
    typeof input === 'object' &&
    input != null &&
    '__typename' in input &&
    input.__typename === 'NavigationItem'
  );
}
