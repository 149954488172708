import type {
  MetadataProviderFragment,
  SlugFragment
} from '~/lib/ContentfulGraphqlService';

export function isMetaDataProviderFragment(
  input: unknown
): input is MetadataProviderFragment & { metadata?: SlugFragment } {
  return (
    typeof input === 'object' &&
    input != null &&
    'metadata' in input &&
    typeof input.metadata === 'object' &&
    input.metadata != null &&
    '__typename' in input.metadata &&
    input.metadata.__typename === 'Metadata'
  );
}
